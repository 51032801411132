<template>
  <el-dialog
    :title="todo == 'add' ? '【新增】应付报价基础数据' : (todo == 'edit' ? '【修改】应付报价基础数据' : '【查看】应收报价基础数据')"
    :close-on-click-modal="false"
    width="50%"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="100px" class="base-receipt-form">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="报价模板" prop="templateId">
            <el-select class="item-choose" v-model="dataForm.templateId" :disabled="isView" style="width: 100%;" filterable clearable placeholder="请选择">
              <el-option
                v-for="item in templateList"
                :key="item.id"
                :label="item.templateName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车型" prop="vehicleType">
            <el-select class="item-choose" v-model="dataForm.vehicleType" :disabled="isView"  style="width: 100%;" filterable clearable placeholder="请选择">
              <el-option
                v-for="item in dictTypeMap.vehicle_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="提货省市区" prop="takeCityList">
            <el-cascader
              v-model="dataForm.takeCityList"
              :options="$store.state.areaList"
              clearable
              filterable
              style="width: 100%"
              change-on-select
              :disabled="isView"
              :props="{ checkStrictly: false, value : 'name', label : 'name' }"
              placeholder="选择省-市-区"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="提货点" prop="takeAddress">
            <el-input v-model="dataForm.takeAddress" placeholder="请填写提货点" :disabled="isView" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="卸货省市区" prop="giveCityList">
            <el-cascader
              v-model="dataForm.giveCityList"
              :options="$store.state.areaList"
              clearable
              filterable
              change-on-select
              style="width: 100%"
              :disabled="isView"
              :props="{ checkStrictly: false, value : 'name', label : 'name' }"
              placeholder="选择省-市-区"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="卸货点" prop="giveAddress">
            <el-input v-model="dataForm.giveAddress" placeholder="请填写卸货点" :disabled="isView" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="订单类型" prop="orderType">
            <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" :disabled="isView"  filterable clearable placeholder="请选择">
              <el-option
                v-for="item in dictTypeMap.orderType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="价格" prop="price">
            <el-input v-model="dataForm.price" placeholder="最多2位小数" :disabled="isView" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
     <!-- <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dataForm.remark" placeholder="备注" :disabled="isView" type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      calculationList: [],
      templateList: [],
      dataForm: {
        id: null,
        status: 1,
        baseType: 2,
        templateId: null,
        vehicleType: null,
        takeCityList: [],
        takeAddress: null,
        giveCityList: [],
        giveAddress: null,
        orderType: null,
        price: null
      },
      todo: 'view',
      dataRule: {
        templateId: this._Valid.init(['null']),
        vehicleType: this._Valid.init(['null']),
        takeCityList: this._Valid.init(['null']),
        giveCityList: this._Valid.init(['null']),
        orderType: this._Valid.init(['null']),
        price: this._Valid.init(['price', 'null']),
        giveAddress: this._Valid.init(['null'])
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  methods: {
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.$http({
        url: this.$http.adornUrl('/priceBase/findInit'),
        method: 'get',
        params: this.$http.adornParams({
          'baseType': this.dataForm.baseType
        })
      }).then(({ data }) => {
        this.calculationList = data.calculationList
        this.templateList = data.templateList
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/priceBase/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data) {
              this.dataForm.id = data.id
              this.dataForm.templateId = data.templateId
              this.dataForm.vehicleType = data.vehicleType
              this.dataForm.takeCityList = data.takeCityList
              this.dataForm.takeAddress = data.takeAddress
              this.dataForm.giveCityList = data.giveCityList
              this.dataForm.giveAddress = data.giveAddress
              this.dataForm.orderType = data.orderType
              this.dataForm.price = data.price
              this.dataForm.status = data.status
            }
          })
        }
      })
    },
    clearForm () {
      this.dataForm.id = null
      this.dataForm.templateId = null
      this.dataForm.vehicleType = null
      this.dataForm.takeCityList = []
      this.dataForm.takeAddress = null
      this.dataForm.giveCityList = []
      this.dataForm.giveAddress = null
      this.dataForm.orderType = null
      this.dataForm.price = null
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/priceBase/` + methodName),
            method: method,
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>
<style lang="less">
.base-receipt-form {
  .el-col {
    padding-left: 15px !important;
    padding-right: 15px !important
  }
}
</style>
